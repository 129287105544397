import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../components'
import { Seo } from '../components/base'
import { LayoutBackground } from '../components/shared'
import { PressContactForm } from '../components/forms'

const PressContactPage = ({ data, pageContext }) => {
  if (!data || Object.keys(data.pressContactPage.nodes).length === 0) {
    return null
  }
  const { title, subtitle, desc, sendMessage, errorMessage, pressContactForm } =
    data.pressContactPage.nodes[0]
  const languages = { pageContext }

  const layoutBackgroundHeaderComponent = (
    <div className="px-4 max-w-6xl mx-auto mb-14 md:mb-24">
      <h1 className="mb-5 md:mb-11 text-center">{title}</h1>
      <p className="px-4 sm:px-8 md:px-4 lg:px-0 md:px-0">{desc}</p>
    </div>
  )

  return (
    <Layout standardPaddingTopFromNavbarToMain translations={languages}>
      <Seo title={title} description={desc} />
      <LayoutBackground headerComponent={layoutBackgroundHeaderComponent}>
        <section className="md:px-10 lg:px-0 max-w-6xl mx-auto">
          <PressContactForm
            formData={pressContactForm}
            markets={data.markets.nodes}
            sendMessage={sendMessage}
            errorMessage={errorMessage}
          />
        </section>
      </LayoutBackground>
    </Layout>
  )
}

export default PressContactPage

export const pageQuery = graphql`
  query ($language: String! = "de_de") {
    pressContactPage: allSanityPressContactPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        title
        subtitle
        desc
        errorMessage
        sendMessage
        pressContactForm {
          emailAddress {
            name
            placeholder
            required
            type
            initialValue
          }
          firstName {
            name
            placeholder
            required
            type
            initialValue
          }
          lastName {
            name
            placeholder
            required
            type
            initialValue
          }
          media {
            name
            placeholder
            required
            type
            initialValue
          }
          message {
            name
            placeholder
            required
            initialValue
          }
          phoneNumber {
            name
            placeholder
            required
            type
            initialValue
          }
          privacy {
            name
            _rawLabel
            required
            initialValue
          }
          salutation {
            name
            options
            placeholder
            required
            initialValue
          }
        }
      }
    }
    markets: allSanityMarket(filter: { status: { in: ["9"] } }) {
      nodes {
        ...MarketQuery
      }
    }
  }
`
